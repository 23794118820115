import CoreProvider from "./components/core-sub/context";
import { app } from "./components/core-sub/Controller/firebase";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
import { Loading } from "./components/core-sub/Loading";
import { appMenu } from "./controllers/app.menu";
import { PageTeachSectionContainer } from "./pages/teach.section.container";

const PageNotFound = lazy(() => import("./pages/notfound"));
const PageHome = lazy(() => import("./pages/home"));

//ANCHOR - FEEDS
const PageFeeds = lazy(() => import("./pages/feeds.view"));
const PageFeedsPost = lazy(() => import("./pages/feeds.post"));

//ANCHOR - PROFILE
const PageProfile = lazy(() => import("./pages/profile"));
const PageProfileEdit = lazy(() => import("./pages/profile.edit"));
const PageProfileView = lazy(() => import("./pages/profile.view"));

//ANCHOR - STUDY
const PageStudyManage = lazy(() => import("./pages/study.manage"));
const PageStudyView = lazy(() => import("./pages/study.view"));
const PageStudyQuiz = lazy(() => import("./pages/study.quiz"));

//ANCHOR - TEACH
const PageTeachManage = lazy(() => import("./pages/teach.manage"));
const PageTeachMaterialManage = lazy(
  () => import("./pages/teach.material.manage")
);
const PageTeachMaterialLessonEdit = lazy(
  () => import("./pages/teach.material.lesson.edit")
);
const PageTeachMaterialAssignmentEdit = lazy(
  () => import("./pages/teach.material.assignment.edit")
);
const PageTeachMaterialQuizEdit = lazy(
  () => import("./pages/teach.material.quiz.edit")
);
const PageTeachSyllabus = lazy(() => import("./pages/teach.syllabus"));
const PageTeachSyllabusEdit = lazy(() => import("./pages/teach.syllabus.edit"));
const PageTeachQuizManage = lazy(() => import("./pages/teach.quiz.manage"));
const PageTeachQuestionManage = lazy(
  () => import("./pages/teach.question.manage")
);
const PageTeachQuestionEdit = lazy(() => import("./pages/teach.question.edit"));
const PageTeachSectionManage = lazy(
  () => import("./pages/teach.section.manage")
);
const PageTeachSectionStudentManage = lazy(
  () => import("./pages/teach.section.student.manage")
);
const PageTeachSectionAssignmentManage = lazy(
  () => import("./pages/teach.section.assignment.manage")
);
const PageTeachSectionAssignmentSubmit = lazy(
  () => import("./pages/teach.section.assignment.submit")
);
const PageTeachSectionGrading = lazy(
  () => import("./pages/teach.section.grading")
);
const PageTeachSectionClassManage = lazy(
  () => import("./pages/teach.section.class.manage")
);
const PageTeachEnrollee = lazy(() => import("./pages/teach.enrollee"));
//FIXME[epic=TEACH] - PageTeachSectionClassView
// const PageTeachSectionClassView = lazy(() => import("./pages/teach.section.class.view"))
const PageTeachCoursePreview = lazy(
  () => import("./pages/teach.preview.course")
);
const PageTeachQuizPreview = lazy(() => import("./pages/teach.preview.quiz"));
//FIXME[epic=TEACH] - PageTeachLessonPreview
// const PageTeachLessonPreview = lazy(() => import("./pages/teach.preview.lesson"))
const PageQuizPreview = lazy(() => import("./pages/teach.quiz.preview"));
const PageLessonPreview = lazy(() => import("./pages/teach.lesson.preview"));

// const PageTeachSectionClassView = lazy(() => import("./pages/teach.section.class.view"))

//ANCHOR - POST
const PagePostManage = lazy(() => import("./pages/post.manage"));
const PagePostEdit = lazy(() => import("./pages/post.edit"));
const PagePostView = lazy(() => import("./pages/post.view"));

//ANCHOR - BOOK
const PageBookManage = lazy(() => import("./pages/book.manage"));
const PageBookEdit = lazy(() => import("./pages/book.edit"));
const PageBookView = lazy(() => import("./pages/book.view"));

//ANCHOR - SLIDESHOW
const PageSlideshowManage = lazy(() => import("./pages/slideshow.manage"));
const PageSlideshowEdit = lazy(() => import("./pages/slideshow.edit"));
const PageSlideshowView = lazy(() => import("./pages/slideshow.view"));

//ANCHOR - MAPS
const PageMapsAreaManage = lazy(() => import("./pages/maps.area.manage"));
const PageMapsAreaEdit = lazy(() => import("./pages/maps.area.edit"));
const PageMapsRouteManage = lazy(() => import("./pages/maps.route.manage"));
const PageMapsRouteEdit = lazy(() => import("./pages/maps.route.edit"));
const PageMapsMarkerManage = lazy(() => import("./pages/maps.marker.manage"));
const PageMapsMarkerEdit = lazy(() => import("./pages/maps.marker.edit"));
const PageMapsMappackManage = lazy(() => import("./pages/maps.mappack.manage"));
const PageMapsMappackEdit = lazy(() => import("./pages/maps.mappack.edit"));

//ANCHOR - IMAGES
const PageImageManage = lazy(() => import("./pages/images.manage"));
const PageImageAlbumManage = lazy(() => import("./pages/images.album.manage"));
const PageImageAlbumEdit = lazy(() => import("./pages/images.album.edit"));

//ANCHOR - FILES
const PageFilesManage = lazy(() => import("./pages/files.manage"));
const PageFilesStar = lazy(() => import("./pages/files.star"));
const PageFilesTrash = lazy(() => import("./pages/files.trash"));

//ANCHOR - PLAYGROUND
const PagePlayground = lazy(() => import("./pages/playground"));

const Routing = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* //SECTION - TEST */}
          {process.env.NODE_ENV === "development" && (
            <Route path="/test" element={<PagePlayground />} />
          )}
          {/* //!SECTION */}
          {/* //SECTION - FEEDS */}
          <Route path="/feeds/post/:uid/:postId" element={<PageFeedsPost />} />
          <Route path="/feeds" element={<PageFeeds />} />
          {/* //!SECTION */}
          {/* //SECTION - PROFILE */}
          <Route
            path="/profile/*"
            element={
              <Routes>
                <Route path="/view/:userId" element={<PageProfileView />} />
                <Route path="/edit" element={<PageProfileEdit />} />
                <Route path="/:userId" element={<PageProfileView />} />
                <Route path="/" element={<PageProfile />} />
              </Routes>
            }
          />
          {/* //!SECTION */}
          {/* //SECTION - STUDY */}
          <Route
            path="/study/*"
            element={
              <Routes>
                <Route
                  path="/v/:uid/:courseId/material-quiz/:itemId"
                  element={<PageStudyQuiz />}
                />
                <Route
                  path="/v/:uid/:courseId/:type/:itemId"
                  element={<PageStudyView />}
                />
                <Route path="/v/:uid/:courseId" element={<PageStudyView />} />
                <Route path="/" element={<PageStudyManage />} />
              </Routes>
            }
          />
          {/* //!SECTION */}
          {/* //SECTION - TEACH */}
          <Route
            path="/teach/*"
            element={
              <Routes>
                <Route
                  path="/v/:courseId"
                  element={<PageTeachCoursePreview />}
                />
                {/* <Route path="/edit/:courseId/section/:sectionId/class/:classId" element={<PageTeachSectionClassView />} />
            <Route path="/edit/:courseId/section/:sectionId/class" element={<PageTeachSectionClassManage />} />
            <Route path="/edit/:courseId/section/:sectionId/grading" element={<PageTeachSectionGrading />} />
            <Route path="/edit/:courseId/section/:sectionId/assignment/:assignmentId" element={<PageTeachSectionAssignmentSubmit />} />
            <Route path="/edit/:courseId/section/:sectionId/assignment" element={<PageTeachSectionAssignmentManage />} />
            <Route path="/edit/:courseId/section/:sectionId/student" element={<PageTeachSectionStudentManage />} />
            <Route path="/edit/:courseId/section/:sectionId" element={<PageTeachSectionStudentManage />} /> 
            <Route path="/edit/:courseId/section" element={<PageTeachSectionManage />} /> */}
                <Route
                  path="/preview/:courseId/*"
                  element={
                    <Routes>
                      <Route
                        path="/quiz/:quizId"
                        element={<PageTeachQuizPreview />}
                      />
                      <Route
                        path="/assignment/:assignmentId"
                        element={<PageTeachCoursePreview />}
                      />
                      <Route
                        path="/lesson/:lessonId"
                        element={<PageTeachCoursePreview />}
                      />
                      <Route path="/" element={<PageTeachCoursePreview />} />
                    </Routes>
                  }
                />
                <Route
                  path="/edit/:courseId/section/:sectionId/*"
                  element={
                    <PageTeachSectionContainer>
                      <Routes>
                        <Route
                          path="/student"
                          element={<PageTeachSectionStudentManage />}
                        />
                        <Route
                          path="/assignment"
                          element={<PageTeachSectionAssignmentManage />}
                        />
                        <Route
                          path="/grading"
                          element={<PageTeachSectionGrading />}
                        />
                        <Route
                          path="/class"
                          element={<PageTeachSectionClassManage />}
                        />
                      </Routes>
                    </PageTeachSectionContainer>
                  }
                />
                <Route path="/lesson/preview" element={<PageLessonPreview />} />
                <Route path="/quiz/preview" element={<PageQuizPreview />} />
                <Route
                  path="/edit/:courseId/enrollee"
                  element={<PageTeachEnrollee />}
                />
                <Route
                  path="/edit/:courseId/section/:sectionId/assignment/:assignmentId"
                  element={<PageTeachSectionAssignmentSubmit />}
                />
                <Route
                  path="/edit/:courseId/section"
                  element={<PageTeachSectionManage />}
                />
                <Route
                  path="/edit/:courseId/quiz/v/:quizId/"
                  element={<PageTeachQuizManage />}
                />
                <Route
                  path="/edit/:courseId/quiz/:quizId/question/:questionId"
                  element={<PageTeachQuestionEdit />}
                />
                <Route
                  path="/edit/:courseId/quiz/:quizId"
                  element={<PageTeachQuestionManage />}
                />
                <Route
                  path="/edit/:courseId/quiz"
                  element={<PageTeachQuizManage />}
                />
                <Route
                  path="/edit/:courseId/syllabus/edit"
                  element={<PageTeachSyllabusEdit />}
                />
                <Route
                  path="/edit/:courseId/syllabus"
                  element={<PageTeachSyllabus />}
                />
                <Route
                  path="/edit/:courseId/material/quiz/:quizId"
                  element={<PageTeachMaterialQuizEdit />}
                />
                <Route
                  path="/edit/:courseId/material/assignment/:assignmentid"
                  element={<PageTeachMaterialAssignmentEdit />}
                />
                <Route
                  path="/edit/:courseId/material/lesson/:lessonId"
                  element={<PageTeachMaterialLessonEdit />}
                />
                <Route
                  path="/edit/:courseId/material"
                  element={<PageTeachMaterialManage />}
                />
                <Route
                  path="/edit/:courseId"
                  element={<PageTeachMaterialManage />}
                />
                <Route path="/" element={<PageTeachManage />} />
              </Routes>
            }
          />
          {/* //!SECTION */}
          {/* //SECTION - POST */}
          <Route
            path="/post/*"
            element={
              <Routes>
                <Route path="/v/:postId" element={<PagePostView />} />
                <Route path="/edit/:postId" element={<PagePostEdit />} />
                <Route path="/*" element={<PagePostManage />} />
              </Routes>
            }
          />
          {/* //!SECTION */}
          {/* //SECTION - BOOK */}
          <Route
            path="/book/*"
            element={
              <Routes>
                <Route path="/v/:userId/:bookId" element={<PageBookView />} />
                <Route path="/edit/:bookId" element={<PageBookEdit />} />
                <Route path="/*" element={<PageBookManage />} />
              </Routes>
            }
          />
          {/* //!SECTION */}
          {/* //SECTION - SLIDESHOW */}
          <Route
            path="/slideshow/*"
            element={
              <Routes>
                <Route path="/v/:slideshowId" element={<PageSlideshowView />} />
                <Route
                  path="/edit/:slideshowId"
                  element={<PageSlideshowEdit />}
                />
                <Route path="/*" element={<PageSlideshowManage />} />
              </Routes>
            }
          />
          {/* //!SECTION */}
          {/* //SECTION - MAPS */}
          <Route
            path="/maps/*"
            element={
              <Routes>
                <Route path="/area/edit/:id" element={<PageMapsAreaEdit />} />
                <Route path="/route/edit/:id" element={<PageMapsRouteEdit />} />
                <Route
                  path="/marker/edit/:id"
                  element={<PageMapsMarkerEdit />}
                />
                <Route
                  path="/mappack/edit/:id"
                  element={<PageMapsMappackEdit />}
                />
                <Route
                  path="/*"
                  element={
                    <Routes>
                      <Route path="/area" element={<PageMapsAreaManage />} />
                      <Route path="/route" element={<PageMapsRouteManage />} />
                      <Route
                        path="/marker"
                        element={<PageMapsMarkerManage />}
                      />
                      <Route
                        path="/mappack/v/:mappackId"
                        element={<PageMapsMappackManage />}
                      />
                      <Route
                        path="/mappack"
                        element={<PageMapsMappackManage />}
                      />
                      <Route path="/" element={<PageMapsMappackManage />} />
                    </Routes>
                  }
                />
              </Routes>
            }
          />
          {/* //!SECTION */}
          {/* //SECTION - IMAGES */}
          <Route
            path="/images/*"
            element={
              <Routes>
                <Route
                  path="/album/:albumId"
                  element={<PageImageAlbumEdit />}
                />
                <Route path="/album" element={<PageImageAlbumManage />} />
                <Route path="/image" element={<PageImageManage />} />
                <Route path="/*" element={<PageImageManage />} />
              </Routes>
            }
          />
          {/* //!SECTION */}
          {/* //SECTION - FILES */}
          <Route
            path="/files/*"
            element={
              <Routes>
                <Route path="/trash" element={<PageFilesTrash />} />
                <Route path="/star" element={<PageFilesStar />} />
                <Route path="/f/:folderId" element={<PageFilesManage />} />
                <Route path="/file" element={<PageFilesManage />} />
                <Route path="/" element={<PageFilesManage />} />
              </Routes>
            }
          />
          {/* //!SECTION */}
          <Route path="/" element={<PageHome />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

function App() {
  return (
    <CoreProvider
      firebaseApp={app}
      logo="/assets/logo/mek.svg"
      appMenu={appMenu}
    >
      <Routing />
    </CoreProvider>
  );
}

export default App;

import { AppMenu } from "../components/core-sub/app.menu";

export const appMenu: AppMenu[] = [
  { label: "Feeds", type: "Link", to: "/feeds", icon: ["fad", "scroll"] },
  { label: "PROFILE", type: "Link", to: "/profile", icon: ["fad", "id-card"] },
  { type: "divider" },
  {
    label: "STUDY",
    type: "Link",
    to: "/study",
    icon: ["fad", "graduation-cap"],
  },
  {
    label: "TEACH",
    type: "Link",
    to: "/teach",
    icon: ["fad", "chalkboard-user"],
  },
  { type: "divider" },
  { label: "POST", type: "Link", to: "/post", icon: ["fad", "file-alt"] },
  { label: "BOOK", type: "Link", to: "/book", icon: ["fad", "book"] },
  {
    label: "SLIDESHOW",
    type: "Link",
    to: "/slideshow",
    icon: ["fad", "images"],
  },
  { type: "divider" },
  { label: "MAPS", type: "Link", to: "/maps", icon: ["fad", "map-location-dot"] },
  { label: "IMAGES", type: "Link", to: "/images", icon: ["fad", "image"] },
  { label: "FILES", type: "Link", to: "/files", icon: ["fad", "file"] },
];

import { BoxProps, List, ListItemText } from "@mui/material";
import { BackLink } from "../../components/core-sub/BackLink";
import { Breadcrumb } from "../../components/core-sub/ContentHeader";
import { useCore } from "../../components/core-sub/context";
import { Course, Section } from "../../components/core-sub/Controller";
import { KuiListItemButton } from "../../components/core-sub/KuiListItemButton";
import MainContainer from "../../components/core-sub/MainContainer";
import { TitleEdit } from "../../components/core-sub/TitleEdit";
import { LocaleKey } from "../../components/core-sub/Translate/en_th";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

//ANCHOR - ContextType
type ContextType = {
  state: {
    loading: boolean;
    course: Course;
    section: Section;
  };
  setState: Dispatch<SetStateAction<ContextType["state"]>>;
  breadcrumbs: Breadcrumb[];
};

const SectionContext = createContext<ContextType>({
  state: { loading: true, course: new Course(), section: new Section() },
  setState: () => {},
  breadcrumbs: [],
});

export const useSection = () => useContext(SectionContext)

export const PageTeachSectionContainer= (props: BoxProps) => {
  const { user, t } = useCore();
  const { courseId, sectionId, "*": tab } = useParams();
  const [state, setState] = useState<ContextType["state"]>({
    loading: true,
    course: new Course(),
    section: new Section(),
  });
  const nav = useNavigate();
  const breadcrumbs: Breadcrumb[] = [
    { label: t("Home"), to: "/" },
    { label: t("Course"), to: `/teach/edit/${courseId}` },
    { label: t("Section"), to: `/teach/edit/${courseId}/section` },
  ];

  useEffect(() => {
    if (user.loading === false && user.data && courseId && sectionId) {
      Course.getOne(user.data, courseId).then((course) =>
        setState((s) => ({ ...s, course }))
      );
      return Section.watch(user.data, sectionId, (section) => {
        setState((s) => ({ ...s, section, loading: false }));
      });
    }
  }, [user, courseId, sectionId]);

  return (
    <SectionContext.Provider value={{ state, setState, breadcrumbs }}>
      <MainContainer
        signInOnly
        sidebar={
          <>
            <BackLink divider to={`/teach/edit/${courseId}/section`} />
            <TitleEdit
              value={state.section?.title}
              onChange={(value) => state.section.update("title", value)}
            />
            <List dense>
              {(
                [
                  { label: "Student", key: "student" },
                  // { label: "Collaborated Teacher", key: "teacher" },
                  // { label: "Assignment", key: "assignment" },
                  // { label: "Grading", key: "grading" },
                  // { label: "Class", key: "class" },
                  // { label: "Inbox", key: "inbox" },
                ] as { label: LocaleKey; key: string }[]
              ).map((item) => (
                <KuiListItemButton
                  divider
                  key={item.key}
                  selected={(tab || "student") === item.key}
                  onClick={() =>
                    nav(`/teach/edit/${courseId}/section/${sectionId}/${item.key}`)
                  }
                >
                  <ListItemText
                    primary={t(item.label)}
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                </KuiListItemButton>
              ))}
            </List>
          </>
        }
      >
        {props.children}
      </MainContainer>
    </SectionContext.Provider>
  );
};
